.header {
    border: 1px solid rgb(92, 92, 92);
    position: fixed;
    z-index: 100;
    background-color: rgb(39, 49, 138);
    height: 3.2rem;
    top: 0;
    left: 0;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: right;
}

.logout_button {
    margin-right: 35px;
    padding: 5px;
    font-weight: 400;
    font-size: 1.1rem;
    border-radius: 6px;
    background-color: rgb(224, 230, 255);
    border: 2px solid black;
    padding-inline: 15px;
}

.logout_button:hover {
    background-color: #c9dfff;
    cursor: pointer;
}