.login_page {
    display: flex;
    height: 100vh; /* Use viewport height to ensure it covers full screen */
    background-image: url(../img/skip_ship.jpg);
    background-repeat: no-repeat;
    background-size: cover; /* This makes the background image cover the entire area */
    background-position: 85% bottom; 
    justify-content: center;
    align-items: center;
}
.login_container {
    padding: 2rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    border-radius: 7px;
    background-color: rgba(245, 245, 245, 0.693);
    gap: 1rem;
    width: 30vw;
    min-width: 300px;
    max-width: 400px;
    background-image: url(../img/skp.png);
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 10px;
    background-size: 20%;
    box-shadow: 4px 4px 7px -2px rgba(0,0,0,0.65);
    
}

.login_title {
    margin-bottom: 14px;
    font-size: 2.5rem;
}

.login_button {
    width: 50%;
    margin-top: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.3rem;
    border-radius: 5px;  
    background-color: rgba(140, 228, 255, 0.425);
}

.login_button:hover {
    background-color: darkgray; /* Change background color on hover */
    color: white; /* Optionally, change text color on hover */
}

.login_username, .login_password {
    width: 100%; /* Take full width of the container */
    padding: 0.6rem 0.3rem 0.6rem 0.5rem;
    font-size: 1rem; /* Adjust font size */
}