.dashboard {
    position: relative; /* Needed for absolute positioning of child elements */
    width: 100%;
    height: 100%; /* Assuming full height */
    overflow: hidden; /* Hide overflow to keep the transition smooth */
}

.dashboard_parent {
    display: flex;
    height: 100%; /* Subtract header height */
    background-color: whitesmoke;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    min-width: 800px;
    padding-top: 4rem; /* Add padding equal to header height */
    overflow-y: auto;  /* Vertical scrolling */
    overflow-x: auto;  /* Horizontal scrolling */
    white-space: nowrap; /* Prevent content wrapping */
    position: absolute; /* Position them absolutely */
    width: 100%;
    height: 100%; /* Assuming full height */
    transition: transform 0.5s ease-in-out;
}


.slide-left {
    transform: translateX(0); /* Show this when on SingleUserInfo page */
}

.slide-right {
    transform: translateX(100%); /* This will slide in from the right */
}

/* Optional: if you want to manage sliding out */
.slide-left {
    transform: translateX(-100%); /* Move out to the left */
}

.add_user_manualy {
    background-color: white;
    flex: 0.6;
    width: 96%;
    min-height: 60px;
    max-height: 60px;
    margin: auto;
    margin-top: 10px;
    border-radius: 7px;
    border: 1px solid #3a3a3a9f;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
}

.add_user_manualy input {
    padding: 6px;
    font-size: 1rem;
    border-radius: 6px;
    border: 1px solid black;
}

.back_button {
    position: absolute;
    top: 8px;
    z-index: 2000;
    left: 40px;
    background-color: rgb(224, 230, 255) !important;
    padding: 6px;
    font-size: 1.1rem;
    border-radius: 6px;
    border: 1px solid black;
    background-color: whitesmoke;
}

.back_button:hover {
    cursor: pointer;
    background-color: #c9dfff !important;
}

.add_user_manualy button {
    padding: 6px;
    font-size: 1rem;
    border-radius: 6px;
    border: 1px solid black;
    background-color: whitesmoke;
    margin-left: auto;
    margin-right: 20px;
}

.add_user_manualy p {
    margin-left: 30px;

}

.add_user_manualy button:hover {
    background-color: lightblue;
    cursor: pointer;
}

.add_user_manualy h2 {
    margin-left: 14px;
    margin-top: 7px;
}

.manual_user_serial {
    margin-left: 20px;
}

.selected_user_info {
    background-color: white;
    flex: 0.7;
    width: 96%;
    min-height: 100px;
    max-height: 150px;
    margin: auto;
    margin-top: 1rem;
    border-radius: 7px;
    border: 1px solid #3a3a3a9f;
}

.user_list_single_element {
    background-color: rgb(255, 255, 255);
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #3a3a3a83;
    flex-shrink: 0; 
    font-size: 0.7em;
}

.user_list {
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 96%;
    min-height: 300px;
    margin: auto;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #3a3a3a9f;
    overflow-y: auto;
}


.user_list_content {
    display: flex;
/* Ensure spacing between fields */
    width: 100%; /* Ensure the entire row takes full width */
    align-items: center;
    height: 100%;
    font-size: 1rem;
}

/* Specific styles for the header */
.user_list_header {
    background-color: #f1f1f1; /* Gray background */
    min-height: 40px; /* Height for the header */
    border-bottom: 2px solid rgb(119, 114, 114); /* Bottom border */
    display: flex; /* Make it a flex container */
    align-items: center; /* Center items vertically */
    width: 100%;
}

.user_list_header .user_field {
    /* maybe font weight */
}

/* User fields style */
.user_field {
    flex: 1; /* Equal width for each field */
    border-right: 1px solid #ccc; /* Fixed border between fields */
    display: flex; /* Use Flexbox */
    flex-direction: column; /* Stack key and value vertically */
    padding-left: 6px;
    font-weight: 400;
    text-align: left; /* Align text to the left */
    align-items: flex-start; /* Align items to the left */
    justify-content: center; /* Vertically center the content */
    height: 100%; /* Ensure full height */
    box-sizing: border-box; /* Ensure padding doesn't affect width */
    color: #0052cc;
    text-wrap: balance;
}


/* Remove the right border from the last field */
.user_field:last-child {
    border-right: none;
}

/* Remove the right border from the last field */
.user_field:last-child {
    border-right: none;
}

.user_label {
    font-weight: 500;
}

.dashboard_parent h1 {
    display: flex;
    justify-content: start; /* Ensure spacing between fields */
    padding-top: 15px;
    width: 96%; /* Ensure the entire row takes full width */
    align-items: start;
}


.user_list_single_element:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 8px;
  }

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(229, 229, 229, 0.823); 
    border-radius: 10px;
    border: 1px solid #3a3a3a9f;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(153, 151, 151, 0.849); 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #727070; 
  }
