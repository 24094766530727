.user_info_parent {
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url(../elements/img/skip_ship.jpg);
  background-repeat: no-repeat;
  background-size: cover; /* This makes the background image cover the entire area */
  background-position: 85% bottom; 
  overflow: scroll;
}

.user_info_box {
  width: 94%;
  padding: 5px;
  margin-bottom: 10px;
  border: 2px solid rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-radius: 6px;
  background-color: rgb(242, 255, 254);
}

.user_info_header {
  width: 94%;
  padding: 5px;
  border: 2px solid black;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 6px;
  background-color: rgb(242, 255, 254);
}

.user_info_parent h1 {
  margin-top: 8px;
  margin-right: auto;
  margin-left: 20px;
  margin-bottom: 6px;
  color: rgb(255, 252, 241);
}

.user_info_history {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user_history_title {
  margin-top: 24px;
  margin-bottom: 4px;
  margin-right: auto;
  margin-left: 20px;
  color: rgb(255, 252, 241);
}

.info_row {
  display: grid;
  grid-template-columns: 150px 1fr;
  /* 2 columns: key and value */
  align-items: center;
  gap: 4px;
  /* Space between key and value */
  padding: 4px 0;
  border-bottom: 1px solid #e0e0e0;
}

.info_row:last-child {
  border-bottom: none;
}

.info_key {
  font-weight: 500;
  color: #333;
}

.info_value {
  color: #666;
  text-align: left;
}